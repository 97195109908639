<template>
  <div>
    <b-card-group deck>
      <b-card
          header="Danh sách các whitelist"
          header-tag="header"
      >
        <b-form @submit.prevent="onSubmit" >
          <div class="row">
            <div class="col-md-3">
              <b-form-group
                  id="client_id"
                  label="Merchant:"
                  label-for="input-1"
              >
                <b-form-select v-model="filter.merchant_id" >
                  <option value="">--Chọn--</option>
                  <option v-for="(client, index) in clients" :key="index" :value="client.id">{{ client.name }}</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                  id="campagin_name"
                  label="Tên sự kiện"
                  label-for="input-1"
              >
                <b-form-input
                    id="input-1"
                    v-model="filter.event_name"
                    placeholder="Nhập tên sự kiện..."
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3" >
              <b-form-group
                  id="campaign_status"
                  label="Trạng thái"
                  label-for="input-1"
              >
                <b-form-select v-model="filter.status" >
                  <option value="">Tất cả</option>
                  <option value="1">Active</option>
                  <option value="0">InActive</option>
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-md-3" >
              <label>.</label><br />
              <button
                  class="btn btn-primary btn-primary--icon"
              >
                      <span>
                        <i class="la la-search"></i>
                        <span>Tìm kiếm</span>
                      </span>
              </button>
            </div>
          </div>

        </b-form>
        <div class="row">
          <div class="col-md-12">
            <b-button variant="danger" style="float: right;" @click="toPageCreate()">+Tạo mới</b-button>
          </div>
        </div>
        <div class="row">
          <b-table striped hover :items="items" :fields="fields">
            <template #cell(stt)="data">
              {{ data.index + 1 }}
            </template>
            <template #cell(client)="data">
              <span > {{ data.item.client.name }} </span>
            </template>
            <template #cell(name)="data">
              <span > {{ data.item.event.name }} </span>
            </template>
            <template #cell(status)="data">
              <span class="label font-weight-bold label-lg label-inline label-light-success" v-if="data.item.status == 1"> Active </span>
              <span class="label font-weight-bold label-lg label-inline label-light-danger" v-else> InActive </span>
            </template>
            <template #cell(action)="data">
              <router-link
                  :to="'/combo-voucher/whitelist/edit/' + data.item.id"
              >
                <b-button pill variant="info" size="sm" style="margin-right: 10px;">Sửa </b-button>
              </router-link>


              <b-button
                  @click="destroy(data.item.id)"
                  pill
                  variant="danger"
                  size="sm"
              >Xóa
              </b-button>
            </template>
          </b-table>
          <div class="mt-3">
            <b-pagination
                v-model="currentPage"
                :link-gen="createLinkPage"
                :total-rows="paginate.total"
                :per-page="paginate.limit"
                use-router
                first-number
                last-number
                align="right"
            ></b-pagination>
          </div>
        </div>

      </b-card>
    </b-card-group>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Common from "@/core/mixins/common";

import RepositoryFactory from "@/core/repositories/repositoryFactory";
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");

export default {
  name: "FormComboVoucherCampaign",
  mixins: [Common],
  components: {
  },
  data() {
    return {
      filter: {
        merchant_id: '',
        event_name: '',
        status: ''
      },
      is_edit: false,
      fields: [
        { key: 'stt', label: 'STT' },
        { key: 'client', label: 'Merchant ID' },
        { key: 'name', label: 'Tên sự kiện' },
        { key: 'status', label: 'Trạng thái' },
        { key: 'action', label: 'Tùy chọn' },
      ],
      items: [],
      clients:[],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };

  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Quản lí whitelist ", route: "/combo-voucher/whitelist" },
      { title: "Danh sách whitelist" },
    ]);
  },
  created() {
    this.loadWL();
    this.getClients();
  },
  methods: {
    async getClients() {
      let response = await WalletCampaignRepository.getClients();
      this.clients = response.data.data;
    },
    async loadWL () {
      try {
        this.$bus.$emit("show-loading", true);
        this.filter.page = this.currentPage;
        this.filter.limit = this.paginate.limit;
        let response = await WalletCampaignRepository.getListComboVoucherWL(this.filter);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        this.items = body.data;
        this.paginate.total = body.total;
        this.paginate.totalPage = body.last_page;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    onSubmit () {
      this.currentPage = 1;
      this.paginate = {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadWL();
    },
    async destroy (id) {
      if (confirm("Bạn có chắc chắn muốn xóa whitelist này không?")){
        try {
          this.$bus.$emit("show-loading", true);

          let response = await WalletCampaignRepository.removeComboVoucherWL(id);
          this.$bus.$emit("show-loading", false);
          let body = response.data;
          if (body.error_code == 0) {
            this.$notify({
              group: "foo",
              type: "success",
              title: body.message,
            });
            this.loadWL();
          } else {
            this.$notify({
              group: "foo",
              type: "error",
              title: body.message,
            });
          }
        } catch (error) {
          this.$bus.$emit("show-loading", false);
          this.$notify({
            group: "foo",
            type: "error",
            title: error,
          });
        }
      }

    },
    toPageCreate () {
      this.$router.push('/combo-voucher/whitelist/create')
    }
  },
  watch: {
    currentPage() {
      this.loadWL();
    },
  },

};
</script>